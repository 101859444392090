<template>
  <div>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      background-color="#fff"
      :is-full-page="false"
    >
    </loading>
    <b-alert v-model="flagErr" variant="danger" dismissible>
      {{ errMsg }}
    </b-alert>
    <b-alert v-model="flagSuccess" variant="success" dismissible>
      Orden creada exitosamente
    </b-alert>
    <b-row>
      <b-col lg="7">
        <ClientInfo :payer="payer" />
        <Schedule :clientId="payer.client.id"/>
      </b-col>
      <b-col lg="5">
        <Header
          :plans="plans"
          :payer="payer"
          :clientSchedule="clientSchedule"
          :collectionFee="collectionFee"
        />
        <Resumen
          v-if="order.length > 0"
          :payment="payment"
          :order="order"
          :orderResumen="orderResumen"
        />
        <br />
        <b-button 
          v-if="order.length > 0" 
          variant="warning" 
          class="w-100"
          @click="createOrder">
          Crear pago
        </b-button>
        <Modal :showOrderModal="showOrderModal"/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import moment from "moment";
import { uuid } from "uuidv4";

import { servicePaymentMixin } from "../../../mixins/servicePayment";
import ClientInfo from "./payment_info/ClientInfo";
import Header from "./payment_info/Header";
import Schedule from "./payment_info/Schedule";
import Resumen from "./payment_info/Resumen";
import Modal from "./payment_info/Modal"
export default {
  name: "payment_create",
  components: {
    Loading,
    ClientInfo,
    Header,
    Schedule,
    Resumen,
    Modal
  },
  mixins: [servicePaymentMixin],
  created() {
    this.loading = false; //to be deleted
    this.payer = this.$store.getters.paymentBy;
    // console.log('payer: ', this.payer);
    this.watchs();
    this.fetchWalkers();
    this.fetchClient();
    this.fetchPlans();
  },
  methods: {
    fetchWalkers(){
      let payload = {
        value: this.payer.cities.id,
        label: this.payer.cities.nombre,
      }
      this.$store.dispatch("fetchWalkersByCityId", payload).then(walkers => {
        // commit the walkers in store
      }).catch((error) => {
        console.log('Err fetching walkers')
      })
    },
    fetchClient() {
      let data = {
        clientId: this.payer.client.id
      };
      let getClient = this.$firebase.functions().httpsCallable("getClientById");
      getClient(data)
        .then(response => {
          let client = response.data.data;
          this.payer.client["celular"] = client.celular;
          this.payer.client["email"] = client.email;
          let scheduleLoaded = null
          
          if(Object.keys(client.horario).length > 0){
            scheduleLoaded = client.horario
            this.loadSchedule(scheduleLoaded)
          }
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },
    fetchPlans() {
      let getPlans = this.$firebase.functions().httpsCallable("getPlans");
      getPlans().then(response => {
          this.plans = response.data.data;
          this.collectionFee = this.plans.find(plan => plan.id == 34);
          this.collectionFee = +this.collectionFee.precio;

          this.displacementFee = this.plans.find(plan => plan.id == 35);
          this.displacementFee = +this.displacementFee.precio;

          this.exclusivityFee = this.plans.find(plan => plan.id == 36);
          this.exclusivityFee = +this.exclusivityFee.precio;

          this.cancellationFee = this.plans.find(plan => plan.id == 37);
          this.cancellationFee = +this.cancellationFee.precio;
        })
        .catch(error => {
          console.log(error);
        });
    },
    watchs() {
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === "defPayment") {
          this.payment = this.$store.getters.payment;
        }
        if (mutation.type === "defPlansFiltered") {
          this.plansFiltered = this.$store.getters.plansFiltered;
        }
        if (mutation.type === "defClientSchedule") {
          this.clientSchedule = this.$store.getters.clientSchedule;
          this.clientSchedule.id = this.clientSchedule.id == undefined ? null : this.clientSchedule.id
        }
        if (mutation.type === "defOrder") {
          this.order = this.$store.getters.order;
        }
        if (mutation.type === "defShowOrderModal") {
          this.showOrderModal = this.$store.getters.showOrderModal;
        }
      });
    },
    createOrder() {
      //admin info
      let adminPhone = "";
      let adminEmail = "";
      if (
        this.payer.cities != null &&
        Object.keys(this.payer.cities).length > 0
      ) {
        adminPhone = this.payer.cities.celular;
        let contacts = JSON.parse(this.payer.cities.contactos);
        adminEmail = contacts[0];
      }
      let order = {
        order: this.order
      };

      let invoice = {
        total: this.orderResumen, //Calculated to pay
        montoRecibido: this.payment.amount,
        debe: this.payment.owes,
        sendTo: this.payer.client.email,
        cliente: this.payer.clientName,
        estado: this.payment.statusSelected.label,
        metodo: this.payment.methodSelected,
        fechaPago: this.payment.date ? this.payment.date : "",
        tarifaRecaudo:
          this.payment.methodSelected == "Efectivo" ? this.collectionFee : 0,
        adminCelular: adminPhone,
        adminEmail: adminEmail
      };
      order["invoice"] = invoice;
      order["client"] = this.payer.client.id;

      // console.log(this.payer)
      // console.log(order)
      this.loading = true
      let createServiceOrder = this.$firebase.functions().httpsCallable("createServiceOrderAndInvoice");
      createServiceOrder(order).then(response => {
          // console.log(response.data.data);
          this.flagSuccess = true;
          this.loading = false;
          this.scrollToTop();
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
        });

    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    loadSchedule(schedule){
      
      let id = schedule.id
      let duracion = schedule.duracion
      let festivos = schedule.festivos
      let fechas = JSON.parse(schedule.fechas)

      let dates = []

      this.clientSchedule.dates.map((itemSchedule) => {
        let scheduleFound = fechas.find(fecha => fecha.day == itemSchedule.day)
        if (scheduleFound){
          dates.push(scheduleFound)
        } else{
          dates.push(itemSchedule)
        }
      })
      
      this.clientSchedule.id = id
      this.clientSchedule.dates = dates
      this.clientSchedule.holidays = festivos
      this.clientSchedule.length = duracion
      this.$store.commit("defClientSchedule", this.clientSchedule);
    },
  }
};
</script>

<style lang="scss" scoped></style>
