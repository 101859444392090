<template>
  <div>
    <CCard class="mb-0" style="margin-top:0.5rem;">
      <CCardHeader>
        <strong>Resumen</strong>
      </CCardHeader>
      <CCardBody>
        <b-row>
          <b-col lg="12">
            <div v-for="(item, index) in order">
              <b-card>
                <b-col lg="12">
                  <div>
                    <span
                      class="float-right"
                      style="cursor:pointer"
                      @click="removeElement(index)"
                    >
                      X
                    </span>
                  </div>
                  <div>
                    <strong>Servicio:</strong> {{ item.pedido.articulo_id }}
                  </div>
                  <div>
                    <strong>Cantidad servicios:</strong>
                    {{ item.pedido.cantidad }}
                  </div>
                  <div>
                    <strong>A partir de:</strong>
                    {{ item.pedido.fecha_inicio }}
                  </div>
                  <div>
                    <strong>Precio und:</strong>
                    {{ item.pedido.precio_und }}
                  </div>
                  <div v-if="item.pedido.tarifa_recuado != 0">
                    <strong>Tarifa recaudo:</strong>
                    {{ item.pedido.tarifa_recaudo }}
                  </div>
                  <div>
                    <strong>Subtotal:</strong>
                    {{
                      item.pedido.precio_und * item.pedido.cantidad +
                        item.pedido.tarifa_recaudo
                    }}
                  </div>
                </b-col>
              </b-card>
            </div>
          </b-col>

          <b-col lg="9" style="text-align: right;">
            <strong>Debe</strong>
          </b-col>
          <b-col lg="3" style="text-align: right;">
            <strong>{{ payment.owes }}</strong>
          </b-col>

          <b-col lg="9" style="text-align: right;">
            <strong>Total a pagar</strong>
          </b-col>
          <b-col lg="3" style="text-align: right;">
            <strong>{{ orderResumen }}</strong>
          </b-col>

          <b-col lg="9" style="text-align: right;">
            <strong>Monto recibido</strong>
          </b-col>
          <b-col lg="3" style="text-align: right;">
            <strong>{{ payment.amount }}</strong>
          </b-col>
          <!-- <b-col lg="3" v-if="order.length > 0">
              <b-button @click="createOrder">
                Crear pago
              </b-button>
            </b-col> -->
        </b-row>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
export default {
  name: "resumen",
  props: ["payment", "order", "orderResumen"],
  methods: {
    removeElement: function(indexElement) {
      this.order.splice(indexElement, 1);
      this.$store.commit("defOrder", this.order);
    }
  }
};
</script>

<style lang="scss" scoped></style>
