<template>
  <div>
    <CCard class="mb-0" style="margin-top:0.5rem;">
      <CButton
        block
        color="link"
        class="text-left shadow-none card-header"
        @click="accordionHeader = accordionHeader === 0 ? false : 0"
      >
        <h5 class="m-0">Datos de pago</h5>
      </CButton>
      <CCollapse :show="accordionHeader === 0">
        <CCardBody>
          <b-alert v-model="flagErr" variant="danger" dismissible>
            {{ errMsg }}
          </b-alert>
          <b-row>
            <!-- Payment method -->
            <b-col lg="6">
              <b-form-group>
                <label for="method">Método de pago *</label>
                <b-form-select
                  id="method"
                  :plain="true"
                  :options="methodOptions"
                  v-model.trim="$v.payment.methodSelected.$model"
                  :class="{
                    'form-control is-invalid': $v.payment.methodSelected.$error,
                    'form-control is-valid': !$v.payment.methodSelected.$invalid
                  }"
                >
                  <b-form-invalid-feedback
                    v-if="!$v.payment.methodSelected.required"
                  >
                    El método de pago es obligatorio.
                  </b-form-invalid-feedback>
                </b-form-select>
              </b-form-group>
            </b-col>
          
            <!-- Status -->
            <b-col lg="6">
              <b-form-group>
                Estado *
                <v-select
                  :options="statusOptions"
                  v-model="payment.statusSelected"
                  placeholder="Selecciona"
                  :class="{
                    'form-control is-invalid': $v.payment.statusSelected.$error,
                    'form-control is-valid': !$v.payment.statusSelected.$invalid
                  }"
                >
                </v-select>
                <b-form-invalid-feedback
                  v-if="!$v.payment.statusSelected.required"
                >
                  Debes seleccionar el estado de pago
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- Amount -->
            <b-col sm="6">
              <b-form-group>
                Monto Total ($) *
                <b-form-input
                  type="number"
                  id="amount"
                  placeholder="Ingresa valor"
                  v-model.trim="$v.payment.amount.$model"
                  min="0"
                  :class="{
                    'form-control is-invalid': $v.payment.amount.$error,
                    'form-control is-valid': !$v.payment.amount.$invalid
                  }"
                >
                </b-form-input>
                <b-form-invalid-feedback v-if="!$v.payment.amount.required">
                  El monto es obligatorio
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- Owns -->
            <b-col sm="6">
              <b-form-group>
                Debe ($)
                <b-form-input
                  id="amount"
                  type="number"
                  min="0"
                  placeholder="Ingresa valor"
                  v-model.trim="$v.payment.owes.$model"
                >
                </b-form-input>
              </b-form-group>
            </b-col>

            <!-- Date of payment -->
            <b-col lg="6">
              Fecha de pago
              <b-form-group>
                <CInput
                  type="date"
                  placeholder="dd/mm/yyyy"
                  v-model="payment.date"
                />
              </b-form-group>
            </b-col>

            <!-- Code -->
            <b-col lg="6">
              Id de pago
              <b-form-group>
                <b-form-input type="text" v-model="payment.code">
                </b-form-input>
              </b-form-group>
            </b-col>

            <!-- comments -->
            <b-col lg="12">
              Comentarios
              <CTextarea
                placeholder="Cualquier anotación que desees registrar..."
                rows="2"
                v-model="payment.comments"
              />
            </b-col>
          </b-row>
        </CCardBody>
      </CCollapse>
    </CCard>

    <CCard class="mb-0" style="margin-top:0.5rem;">
      <CButton
        block
        color="link"
        class="text-left shadow-none card-header"
        @click="accordionType = accordionType === 0 ? false : 0"
      >
        <h5 class="m-0">Tipo de servicio</h5>
      </CButton>
      <CCollapse :show="accordionType === 0">
        <CCardBody>
          <b-row>
            <!-- Service -->
            <!-- <b-col lg="6">
              <b-form-group>
                Servicio *
                <v-select
                  :options="servicesOptions"
                  v-model="$v.payment.serviceSelected.$model"
                  placeholder="Selecciona"  
                               
                >
                  <template #search="{attributes, events}">
                    <input
                      class="vs__search"
                      :required="$v.payment.serviceSelected.$error"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                </v-select>
               
              </b-form-group>
            </b-col> -->

            <b-col lg="6">
              <b-form-group>
                Servicio *
                <v-select
                  :options="servicesOptions"
                  v-model="payment.serviceSelected"
                  placeholder="Selecciona"
                  :class="{
                    'form-control is-invalid':
                      $v.payment.serviceSelected.$error,
                    'form-control is-valid': !$v.payment.serviceSelected
                      .$invalid
                  }"
                >
                </v-select>
                <b-form-invalid-feedback
                  v-if="!$v.payment.serviceSelected.required"
                >
                  Debes seleccionar un servicio
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- Type -->
            <b-col lg="6">
              <b-form-group>
                Tipo *
                <v-select
                  :options="typeOptions"
                  v-model="payment.typeSelected"
                  placeholder="Selecciona"
                  :class="{
                    'form-control is-invalid': $v.payment.typeSelected.$error,
                    'form-control is-valid': !$v.payment.typeSelected.$invalid
                  }"
                >
                </v-select>
                <b-form-invalid-feedback
                  v-if="!$v.payment.typeSelected.required"
                >
                  Debes seleccionar un servicio
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- Quantity -->
            <b-col sm="6">
              <b-form-group>
                Cantidad de servicios *
                <b-form-input
                  id="quantity"
                  type="number"
                  min="1"
                  placeholder=""
                  v-model.trim="$v.payment.quantity.$model"
                  :class="{
                    'form-control is-invalid': $v.payment.quantity.$error,
                    'form-control is-valid': !$v.payment.quantity.$invalid
                  }"
                >
                </b-form-input>
                <b-form-invalid-feedback v-if="!$v.payment.quantity.required">
                  Debes indicar la ctdad de servicios.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- pets -->
            <b-col sm="6">
              <b-form-group>
                Cantidad de Mascotas *
                <b-form-input
                  id="pets"
                  type="number"
                  min="1"
                  placeholder=""
                  v-model.trim="$v.payment.pets.$model"
                  :class="{
                    'form-control is-invalid': $v.payment.pets.$error,
                    'form-control is-valid': !$v.payment.pets.$invalid
                  }"
                >
                </b-form-input>
                <b-form-invalid-feedback v-if="!$v.payment.pets.required">
                  Debes indicar la ctdad de servicios.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- Date start service -->

            <b-col sm="6">
              Fecha de inicio 2
              <b-form-group>
                <b-form-datepicker
                  id="serviceStartDate"
                  placeholder="dd/mm/yyyy"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric'
                  }"
                  locale="es"
                  v-model="payment.serviceStartDate"
                  :class="{
                    'form-control is-invalid':
                      $v.payment.serviceStartDate.$error,
                    'form-control is-valid': !$v.payment.serviceStartDate
                      .$invalid
                  }"
                  today-button
                  reset-button
                ></b-form-datepicker>
              </b-form-group>
            </b-col>

            <!-- Plan price filtered -->
            <b-col sm="6">
              <b-form-group v-if="planInfo[0] != null">
                Precio und:
                <b-form-input
                  type="number"
                  id="quantity"
                  min="0"
                  placeholder=""
                  v-model="planInfo[0].precio"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- <b-row v-if="planInfo != null">
            <b-col lg="12">
              <p>
                <strong>Detalle plan seleccionado</strong>
              </p>
            </b-col>

            <b-col lg="12">
              <p>
                Nombre plan:
                {{ planInfo[0] ? planInfo[0].nombre : "" }}
              </p>
            </b-col>

            <b-col lg="6">
              <p>Horas Mín: {{ planInfo[0] ? planInfo[0].horas_min : "" }}</p>
            </b-col>

            <b-col lg="6">
              <p>Horas Max: {{ planInfo[0] ? planInfo[0].horas_max : "" }}</p>
            </b-col>

            <b-col lg="12">
              <b-form-group v-if="planInfo[0] != null">
                Precio und:
                <b-form-input
                  type="number"
                  id="quantity"
                  placeholder=""
                  v-model="planInfo[0].precio"
                >
                </b-form-input>
              </b-form-group>
            </b-col>

            <b-col lg="12">
              <p>
                <strong>Tipo de pago a proveedor</strong>
              </p>

              <b-form-radio-group
                v-model="typePaymentProviderSelected"
                :options="typePaymentProviderOptions"
                class="mb-3"
                value-field="item"
                text-field="name"
                disabled-field="notEnabled"
              ></b-form-radio-group>
            </b-col>

            <b-col lg="6" v-if="typePaymentProviderSelected == 1">
              <p>
                Pago por und:
                {{ planInfo[0] ? planInfo[0].pago_prov_und : "" }}
              </p>
            </b-col>
            <b-col lg="6" v-if="typePaymentProviderSelected == 1">
              <p>
                Pago por adicional:
                {{ planInfo[0] ? planInfo[0].pago_prov_adi : "" }}
              </p>
            </b-col>

            <b-col lg="6" v-else>
              <p>Pago al 80%</p>
            </b-col>

            <b-col lg="6">
              <br />
              <b-button @click="submit">
                Agregar
              </b-button>
            </b-col>
          </b-row> -->
          <b-row>
            <br>
          </b-row>
          <b-row>
            <b-col lg="6">
              
              <b-button @click="submit">
                Agregar
              </b-button>              
            </b-col>
            <b-col lg="6" v-if="flagBtnShow">
              <b-button @click="showServicesFixed">
                Ver servicios
              </b-button>
            </b-col>
          </b-row>
        </CCardBody>
      </CCollapse>
    </CCard>
  </div>
</template>

<script>
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import { uuid } from "uuidv4";
export default {
  name: "paymentHeader",
  props: ["plans", "payer", "clientSchedule", "collectionFee"],
  data() {
    return {
      accordionHeader: 0,
      accordionType: 0,
      //Err
      flagErr: false,
      errMsg: "",
      //Header
      uuidValue: null,
      methodOptions: ["Efectivo", "Bancolombia", "Daviplata", "Nequi"],
      statusOptions: [
        {value: 1, label: "Pendiente"}, 
        {value:2, label: 'Recibido'}
      ],
      payment: {
        methodSelected: null,
        statusSelected: null,
        date: null,
        amount: null, //price
        owes: 0, //debe
        code: null,
        comments: "",

        serviceSelected: null,
        typeSelected: null,
        quantity: null, //number of services
        pets: null,
        serviceStartDate: null
      },
      //Type of service
      servicesOptions: [
        { value: null, label: "SELECCIONE" },
        { value: 1, label: "Paseo" },
        { value: 2, label: "Adiestramiento" },
        { value: 35, label: "Guardería" }
      ],
      typeOptions: [
        { value: null, label: "SELECCIONE" },
        { value: 1, label: "Grupal" },
        { value: 2, label: "Dedicado" }
      ],
      typePaymentProviderSelected: 1,
      typePaymentProviderOptions: [
        { item: 1, name: "Comisión" },
        { item: 2, name: "Porcentaje" }
      ],
      order: [],
      flagBtnShow: false
    };
  },
  created() {
    this.uuidValue = uuid();
  },
  validations: {
    payment: {
      statusSelected: {
        required
      },
      methodSelected: {
        required
      },
      statusSelected: {
        required
      },
      comments: {},
      owes: {},
      amount: {
        required
      },
      serviceSelected: {
        required
      },
      typeSelected: {
        required
      },
      pets: {
        required
      },
      quantity: {
        required
      },
      serviceStartDate: {
        required
      }
    }
  },
  computed: {
    planInfo() {
      let service = this.payment.serviceSelected;
      let quantity = this.payment.quantity; //num of Services
      let pets = this.payment.pets;
      let type = this.payment.typeSelected;
      if (!this.$v.payment.$invalid) {
        this.$store.commit("defPayment", this.payment);

        let plansFiltered = this.plans.filter(
          item =>
            item.servicio == service.label &&
            +item.mascotas == pets &&
            +item.horas_min <= quantity &&
            +item.horas_max >= quantity &&
            item.tipo == type.label
        );
        this.$store.commit("defPlansFiltered", plansFiltered);
        // this.createOrderItem();
        return plansFiltered;
      } else {
        this.$v.$touch();
        // this.$store.commit("defPlansFiltered", null);
        // this.order = []
        // this.$store.commit("defOrder", this.order)
        return "Invalido";
      }
    },
    clientHasSchedule(){
      if(this.clientSchedule != null){
        let activeSchedule = this.clientSchedule.dates.filter(date => date.active == true)
        if(activeSchedule.length > 0){
          return true
        } else {
          return false
        }
      } else {
        return false
      }

    }
  },
  methods: {
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
    submit() {
      this.$v.$touch();
      if (this.$v.payment.$invalid) {
        this.errMsg = "Completa los campos de pago";
        this.flagErr = true;
        this.order = [];
        this.$store.commit("defOrder", this.order);
      } else {
        this.flagErr = false;
        this.createOrderItem();
      }
    },
    createOrderItem() {
      let date1 = new Date();
      
      let itemOrder = {
        pedido: {
          key: "696_17839_" + date1.getTime(),
          medio: this.payment.methodSelected,
          // estado: this.payment.statusSelected,
          pedido_estado: { id: this.payment.statusSelected.value},
          fecha_operacion:
            this.payment.date == null
              ? null
              : moment(this.payment.date).format("yyyy-MM-DD") +
                "T00:00:00.000Z",
          fecha_caducidad: moment(date1)
            .add('days', this.planInfo[0].caducidad)
            .format("yyyy-MM-DD") + "T00:00:00.000Z",
          precio_und: this.planInfo[0].precio,
          precio: this.payment.amount,
          debe: this.payment.owes,
          transaccion_id: this.uuidValue,
          pedido_id: this.payment.code ? this.payment.code : "",

          articulo_id:
            this.payment.serviceSelected.label + " " + this.planInfo[0].tipo,
          cantidad: this.payment.quantity,
          servicios_restantes: this.payment.quantity,
          fecha_inicio: this.payment.serviceStartDate,
          moneda: "COP",

          nombre: this.payer.clientName,
          email: this.payer.client.email,
          cliente: {
            id: this.payer.client.id
          },
          mascota: {
            id: this.payer.id
          },
          comentario: this.payment.comments
        },
        servicio: []
      };
      // if (this.typePaymentProviderSelected == 1) {
      itemOrder.pedido["planes"] = {
        id: this.planInfo[0].id
      };
      // } else if (this.typePaymentProviderSelected == 2) {
      //   itemOrder.pedido["planes"] = {
      //     id: 38 // plan id 38 "otro 80%"
      //   };
      // }

      let itemOrderFound = this.order.find(
        itemOrder => itemOrder.pedido.tarifa_recaudo > 0
      );

      if (itemOrderFound == undefined) {
        itemOrder.pedido["tarifa_recaudo"] =
          this.payment.methodSelected == "Efectivo" ? this.collectionFee : 0;
      } else {
        itemOrder.pedido["tarifa_recaudo"] = 0;
      }

      for (let index = 0; index < this.payment.quantity; index++) {
        let date = new Date();
        let obs = index + 1;
        let service = {
          key: "696_17839_" + date.getTime() + index,
          mascota: {
            id: this.payer.id
          },
          obs_cliente:
            this.payment.serviceSelected.label +
            " " +
            obs +
            "/" +
            this.payment.quantity,
          fecha_inicio: null,
          fecha_fin: null
        };

        itemOrder["servicio"].push(service);
      }
      // console.log('itemOrder: ', itemOrder)
      // console.log('order')
      // console.log(itemOrder)
      // console.log(this.order)
      // console.log('clientSchedule')
      // console.log(this.clientSchedule)

      let payload = {
        clientSchedule: this.clientSchedule,
        order: itemOrder
      };
      // console.log('payload:', payload)

      if (this.clientHasSchedule) {
        let servicesFixSchedule = this.$firebase.functions().httpsCallable("servicesFixSchedule");
        servicesFixSchedule(payload).then(res => {
          let servicesFixed = res.data.data;
          itemOrder["servicio"] = servicesFixed;
          this.order.push(itemOrder);
          this.$store.commit("defOrder", this.order);
          this.$store.commit("defShowOrderModal", true);
          this.flagBtnShow = true;
        }).catch(error => {
          console.log("Err header: ", error);
        });
      } else {
          this.order.push(itemOrder);
          this.$store.commit("defOrder", this.order);
          this.$store.commit("defShowOrderModal", true);
      }

    },
    showServicesFixed() {
      this.$store.commit("defShowOrderModal", true);
    }
  }
};
</script>

<style lang="scss" scoped></style>
